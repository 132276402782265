import React from 'react'
import Clientpage from '../Components/Client/Clientpage'
import Newsletter from "../Components/newsletter/NewsLetter";
function Client() {
  return (
    <div>
      <Clientpage/>
      <Newsletter/>
    </div>
  )
}

export default Client