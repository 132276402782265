import React from 'react'
import Contactus from '../Components/ContactUs/Contactus'

function Contact() {
  return (
    <div>
        <Contactus/>
    </div>
  )
}

export default Contact